<template>
  <div>
    <Header />
    <!--Beneficios-->
    <div class="DivFull_LeftCenter DivPortaBtnVoltaBeneficios">
      <div class="DivBtnVoltaBeneficios" id="DivBtnVoltaBeneficios">
        <a
          @click="Show_Info()"
          href="javascript:void(0)"
          class="BtnVoltaBeneficios"
          id="BtnVoltaBeneficios"
        >
          <img src="./svg/ShowBeneficios.svg" class="" alt="CLOSE" />
        </a>
      </div>
    </div>

    <div class="DivFull_LeftCenter Beneficios" id="Beneficios">
      <div class="container-fluid">
        <div class="col-md-6">
          <div class="rowBlock">
            <h1 class="White" id="">
              Lentes<br />
              Esportivas
            </h1>
            <a
              @click="Hide_Info()"
              href="javascript:void(0)"
              class="BtnFechaBeneficios top10"
              id="BtnFechaBeneficios"
            >
              <img
                src="./svg/CloseBeneficios.svg"
                class="IconeClose"
                alt="CLOSE"
              />
            </a>
          </div>
          <div class="DivEscolhas">
            <div class="row top10">
              <a
                @click="Open_Modal()"
                href="javascript:void(0)"
                class="ShowBeneficios"
                id="AbreModalBeneficios"
              >
                <img src="./svg/OpenCollapse.svg" class="" alt="Saiba mais" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Beneficios-->

    <!--Modal Beneficios-->
    <div class="ModalBeneficios" id="ModalBeneficios">
      <div class="HeaderModal">
        <h2 class="TituloCor1">Lentes Esportivas</h2>
      </div>

      <a
        @click="Close_Modal()"
        href="javascript:void(0)"
        class="BtnCloseModal"
        id="FechaModalBeneficios"
      >
        <img src="./svg/CloseModal.svg" alt="Fechar" />
      </a>

      <div class="container ContainerModal">
        <div class="col-12 NoPadding">
          <div class="DivModalBeneficios" id="DivModalBeneficios">
            <carousel
              :autoplay="false"
              :loop="false"
              :nav="true"
              :dots="true"
              :responsive="{
                0: { items: 1, nav: false },
                450: { items: 1, nav: true },
                1000: { items: 1, nav: true },
              }"
            >
              <div class="item">
                <div class="row">
                  <img
                    src="./images/Esportivas/Banner_Esportivas_1.png"
                    class="img-fluid"
                  />
                </div>

                <div class="row TextPadding top30">
                  <p class="White Opacidade6 TextoSlides">
                    Lentes para óculos de sol, para armações modernas e com
                    curvaturas acentudadas, quando produzidas com tecnologia
                    digital freeform eliminam as distorções que se formam nas
                    extremidades das lentes devido sua curvatura.<br />
                    Estas distorções são incômodas e perigosas para praticantes
                    de esportes pois distorcem a visão periférica, atrapalhando
                    a coordenação corporal em movimentos rápidos.
                  </p>
                </div>
              </div>

              <div class="item">
                <div class="row">
                  <img
                    src="./images/Esportivas/Banner_Esportivas_2.png"
                    class="img-fluid"
                  />
                </div>

                <div class="row TextPadding top30">
                  <p class="White Opacidade6 TextoSlides">
                    A eliminação destas distorções é possível porque a
                    tecnologia FreeForm considera todas as características da
                    armação e também o ângulo pantoscópico e panorâmico (curva
                    que forma a armação) e estas medidas são levada em conta na
                    confecção da correção óptica, fazendo compensações que
                    proporcionam mais precição e conforto visual em toda a
                    superfície das lentes.
                  </p>
                </div>
              </div>

              <div class="item">
                <div class="row justify-content-center">
                  <h5 class="TituloCor1">
                    MEDIDAS QUE AUXILIAM NA PRODUÇÃO DE UMA LENTE PERFEITA,
                    MESMO COM CURVATURA ACENTUADA.
                  </h5>
                </div>

                <div class="row top20">
                  <div class="col-4">
                    <img
                      src="./images/Esportivas/Medida1.jpg"
                      class="img-fluid"
                      alt="Freeform"
                    />

                    <div class="row text-center justify-content-center top30">
                      <h5 class="TituloCor1">DISTÂNCIA VÉRTICE</h5>
                      <p class="MediumTxt White Opacidade5 top5">
                        Distância entre os olhos e a superfície das lentes.
                        Importante para possibilitar amplitude nos campos de
                        visão.
                      </p>
                    </div>
                  </div>

                  <div class="col-4">
                    <img
                      src="./images/Esportivas/Medida2.jpg"
                      class="img-fluid"
                      alt="Freeform"
                    />

                    <div class="row text-center justify-content-center top30">
                      <h5 class="TituloCor1">ÂNGULO PANTOSCÓPICO</h5>
                      <p class="MediumTxt White Opacidade5 top5">
                        Medida da inclinação em que a armação se posiciona, na
                        vertical e em frente aos olhos do usuário.
                      </p>
                    </div>
                  </div>

                  <div class="col-4">
                    <img
                      src="./images/Esportivas/Medida3.jpg"
                      class="img-fluid"
                      alt="Freeform"
                    />

                    <div class="row text-center justify-content-center top30">
                      <h5 class="TituloCor1">ÂNGULO PANORÂMICO</h5>
                      <p class="MediumTxt White Opacidade5 top5">
                        Grau de curvatura da armação escolhida pelo usuário.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </carousel>
          </div>
        </div>
      </div>
    </div>
    <!--Modal Beneficios-->

    <Split style="height: 100vh; display: flex">
      <SplitArea>
        <div class="split a" style="width: 100"></div>
      </SplitArea>
      <SplitArea>
        <div class="split b" style="width: 100"></div>
      </SplitArea>
    </Split>
  </div>
</template>
<script>
import VueSplit from 'vue-split-panel'

import Header from '@/views/menu/components/header.vue'
import carousel from 'vue-owl-carousel'

import {
  Hide_Info,
  Show_Info,
  Open_Modal,
  Close_Modal,
} from '@/views/menu/js/new/Scripts'

export default {
  data() {
    return {
      position: null,
    }
  },
  methods: {
    Hide_Info,
    Show_Info,
    Open_Modal,
    Close_Modal,
  },
  computed: {
    positioncompu() {
      return {
        left: `${this.position}%`,
      }
    },
  },
  components: {
    Header,
    carousel,
  },
  mounted() {
    InicialSet()
  },
}
</script>
<style>
@import './css/Style.css';
@import './css/MyStyle.css';
@import './css/MyLogin.css';
@import './css/MyColors.css';
@import './css/MyMenu.css';
@import './css/MyMenuInterno.css';
@import './css/plugins/animate.css';
@import './css/plugins/hamburger.css';
/* @import './css/plugins/owl.carousel.min.css'; */
@import './css/plugins/owl.theme.default.min.css';

@import './css/Esportivas_Style.css';

@import './css/plugins/images-compare.css';

@import './css/plugins/Compare_Esportivas.css';

.Hide {
  display: none;
}

.no-select {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.split {
  width: 101%;
  height: 100vh;
}
.a {
  background: url('./images/Esportivas/Esportivas_ComDistorcao.jpg');
  background-attachment: fixed;
  background-size: 100% 100%;
}
.b {
  background: url('./images/Esportivas/Esportivas_SemDistorcao.jpg');
  background-attachment: fixed;
  background-size: 100% 100%;
}
.gutter {
  background-color: red !important;
  width: 50px !important;
}
.gutter.gutter-horizontal {
  cursor: e-resize;

  left: 50% !important;
  margin-left: -25px !important;
  margin-right: -25px !important;
  margin-top: -25px !important;
  z-index: 5 !important;
  cursor: pointer !important;
  background: url('./svg/Setas.svg') center center no-repeat !important;
  background-size: 50px !important;
}
</style>
